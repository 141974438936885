import $ from 'jquery'
import { Noise } from 'noisejs';

var canvas = {}
var ctx = {}

class Point {
    constructor(x, y, vx, vy){
        this.x = x;
        this.y = y;
        this.vx = vx;
        this.vy = vy;
    }
}
class Particle {
    constructor(x, y){
        this.x = x;
        this.y = y;
        this.vx = 0;
        this.vy = 0;
        this.prevX = 0;
        this.prevY = 0;
    }
}

var points = []
var particles = []
var noise = {} 
var iteration = 0
var intervalLoop = 0

//adjustable values
var noiseScale = 1200
var pointNum = 80
var particleNum = 0
var maxIterations = 500


function init(){
    var start = Date.now()
    //reinitalise
    points = []
    particles = []
    noise = new Noise(Math.random());
    var w = canvas.width
    var h = canvas.height
    iteration = 0
    if(w<500){
        noiseScale = 350
    }

    clearInterval(intervalLoop)
    intervalLoop = setInterval(() => {
        if(iteration < maxIterations){
            draw()
        } else {
            clearInterval(intervalLoop)
            console.log(`canvas generation time: ${Date.now() - start}`)
        }
    },10)

    //reinitalise
    $('#nameOverlay').css('transition-duration', '0s')
    $('#nameOverlay').css('left', '-100px')
    $('#nameOverlay').css('opacity', '0')

    $('.detailsTxt').css('transition-duration', '0s')
    $('.detailsTxt').css('margin-top', '20px')
    $('.detailsTxt').css('opacity', '0')

    $('#downArrowOverlay').css('transition-duration', '0s')
    $('#downArrowOverlay').css('margin-top', '20px')
    $('#downArrowOverlay').css('opacity', '0')

    var width = $(window).width()
    var widthVal = '100px'
    if(width<700){
        widthVal = '60px'
    }
    if(width<500){
        widthVal = '20px'
    }
    $('#nameOverlay').css('transition-duration', '2s');
    $('#nameOverlay').css('left', widthVal);
    $('#nameOverlay').css('opacity', '1');
    
    setTimeout(() => {
        $('.detailsTxt').css('transition-duration', '0.5s')
        $('.detailsTxt').css('margin-top', '0px')
        $('.detailsTxt').css('opacity', '1')
        setTimeout(() => {
            $('#downArrowOverlay').css('transition-duration', '0.5s')
            $('#downArrowOverlay').css('margin-top', '0px')
            $('#downArrowOverlay').css('opacity', '1')
            setTimeout(() => {
                $('#downArrowOverlay').css('transition-duration', '3s')
                $('#downArrowOverlay').css('opacity', '0.6')
            },500)
        },1500)
    },700)

        
    //background
    ctx.fillStyle = 'black'
    ctx.fillRect(0,0,w,h)

    //generate points
    for(var x=0; x<(w*1.5); x+=pointNum){
        for(var y=0; y<h; y+=pointNum){
            var vx = noise.simplex2(x / noiseScale, y / noiseScale);
            var vy = noise.simplex2(y / noiseScale, x / noiseScale);
            if(vx > -0.1 && vx < 0.1){
                if(vx < 0){
                    vx = -0.1
                } else {
                    vx = 0.1
                }
            }
            if(vy > -0.1 && vy < 0.1){
                if(vy < 0){
                    vy = -0.1
                } else {
                    vy = 0.1
                }
            }
            vx -= 1
            vy -= 0.3
            
            points.push(new Point(x, y, vx, vy))
        }
    }

    particleNum = points.length*10


    //generate particles
    for(var i=0; i<particleNum; i++){
        var px = Math.random()*w
        var py = Math.random()*h
        
        px += (Math.random()*(w))
        if(w<700){
            px += 300
        }

        particles.push(new Particle(px, py))
    }
}


function draw(){
    iteration++

    //move and draw particles
    var red = (((maxIterations-iteration)/(maxIterations*1.5))*255)
    var blue = (iteration/maxIterations)*255
    var opacity = ((iteration/maxIterations)*0.25) + 0.2
    ctx.strokeStyle = `rgba(${red}, 0, ${blue}, ${opacity})`;
    for(var particle of particles){
        //closest point
        var closestPoint = {point: {}, dist: 100}
        for(var point of points){
            var dist = Math.sqrt(Math.pow(particle.x - point.x, 2) + Math.pow(particle.y - point.y, 2));
            if(dist < closestPoint.dist){
                closestPoint.dist = dist
                closestPoint.point = point
            }
        }

        particle.prevX = particle.x
        particle.prevY = particle.y

        var speedMultiplier = (maxIterations - iteration)*0.0001
        particle.vx += (closestPoint.point.vx - particle.vx) * speedMultiplier
        particle.vy += (closestPoint.point.vy - particle.vy) * speedMultiplier

        particle.x += particle.vx * speedMultiplier * 70
        particle.y += particle.vy * speedMultiplier * 70

        ctx.beginPath()
        ctx.moveTo(particle.prevX, particle.prevY)
        ctx.lineTo(particle.x, particle.y)
        ctx.stroke()
        ctx.closePath()
    }

}

export function resize(){
    canvas = document.getElementById('canvasBG');
    ctx = (canvas) ? canvas.getContext("2d") : null;

    const pixelRatio = window.devicePixelRatio || 1;
    const h = window.innerHeight * pixelRatio;
    const w = $(window).width() * pixelRatio;
    
    canvas.height = h;
    canvas.width = w;

    ctx.scale(pixelRatio, pixelRatio);
    
    init();
}


var lastWidth = $(window).width();
$(window).resize(function() {
    setTimeout(() => {
        if($(window).width() !== lastWidth) {
            lastWidth = $(window).width();
            resize();
        }
    },50)
});


