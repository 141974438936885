import React, { useEffect } from 'react';

import './DisplayPage.css';
import { resize } from './canvasBG.js';


const DisplayPage = () => {
    useEffect(() => {
        resize()
    }, [])

    return (
        <div className="DisplayPage">
            <div className="canvasBGContainer">
                <div id="nameOverlay">
                    <h1 className="overlayTxt firstName">Zoran</h1>
                    <h1 className="overlayTxt lastName">Jovanovic</h1>
                </div>
                <div id='detailsOverlay'>
                    <h1 className='detailsTxt'>2nd Year Computer Science @ UOW</h1>
                </div>
                <div id="downArrowOverlay">
                    <div className="arrow" onClick={() => window.scrollBy({ top: window.innerHeight, behavior: 'smooth' })}></div>
                </div>
                <canvas id="canvasBG"></canvas>
            </div>
        </div>
    );
};

export default DisplayPage;


