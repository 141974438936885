import React from 'react';

import './AboutSection.css';

const AboutSection = () => {
    return (
        <div className="AboutSection">

            <div className="box">
                <h1>About</h1>
                <p>
                    Hello! I'm Zoran, a motivated and dedicated individual currently pursuing my Bachelor of Computer Science at UOW. My passion for technology is mirrored in my diverse work experiences. From serving as an IT technician to independently creating programs, I thrive in challenging tech environments.
                </p>
            </div>

            {/* <div className="box">
                <h2>Community Engagement</h2>
                <p>
                    Beyond the digital realm, my problem-solving skills find an outlet in the community. I've captured important events with my videography expertise and have been an active contributor to local happenings.
                </p>
            </div> */}

            <div className="box">
                <h2>Professional Journey</h2>
                <p>
                    My professional journey has been an exciting mix of tech projects, tutoring, and building custom computers. Yet, amidst this, I ensure to indulge in my hobbies. My fascination with photography sees me capturing moments and landscapes from unique perspectives, including from the skies.
                </p>
            </div>

            <div className="box">
                <h2>Discover My Work</h2>
                <p>
                    Dive deeper into my projects at <a href="http://gorillaa.net" target="_blank" rel="noopener noreferrer">gorillaa.net</a> or explore my photographic journey on Instagram at <a href="https://www.instagram.com/zoranscamera/" target="_blank" rel="noopener noreferrer">@zoranscamera</a>.
                </p>
            </div>


            <div className="box">
                <h2>Future Endeavors</h2>
                <p>
                    I eagerly anticipate future collaborations and aim to leave an enduring impact in the tech space and beyond.
                </p>
            </div>

        </div>

    );
};

export default AboutSection;