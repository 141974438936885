import React from 'react';
import { Helmet } from 'react-helmet';
import DisplayPage from './assets/DisplayPage/DisplayPage.js';
import AboutSection from './assets/AboutSection/AboutSection.js';

import './assets/main.css'


function App() {
	return (
		<div className="App">
            <Helmet>
                <title>Zoran Jovanovic</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Zoran Jovanovic's  Website Portfolio." />
                <link rel="canonical" href="https://www.zoranjovanovic.com/" />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Zoran Jovanovic" />
                <meta property="og:description" content="Zoran Jovanovic's Website Portfolio." />
                <meta property="og:url" content="https://www.zoranjovanovic.com/" />

                <meta name="robots" content="index, follow" />
            </Helmet>
            <header className="App-header">
                <DisplayPage />
                <AboutSection />
            </header>
        </div>
	);
}

export default App;
